<template>
    <div class="d-flex list-container">
        <!-- top start -->
        <div class="top d-flex">
            <el-select v-model="currentProjectName" @change="changeProject" placeholder="请选择">
                <el-option v-for="(item, index) in currentProjectList" :key="item.id" :value="index"
                    :label="item.projectName"></el-option>
            </el-select>
            <el-button @click="submitSuggest" type="primary" size="mini">提交图审意见</el-button>
        </div>
        <!-- top end -->
        <el-divider></el-divider>
        <!-- bottom start -->
        <div class="bottom d-flex">
            <div class="left d-flex">
                <div>
                    <el-input placeholder="输入关键字进行过滤" v-model="filterText" @input="(e) => (filterText = utils.validForbid(e))"></el-input>
                    <el-tree class="filter-tree" :data="drawingDirectory" :props="defaultProps" default-expand-all :filter-node-method="filterNode" ref="tree" @node-click="handleNodeClick">
                    </el-tree>
                </div>
                <el-button type="primary" plain @click="writeSuggest" size="mini">填写图审意见</el-button>
            </div>
            <el-divider direction="vertical"></el-divider>
            <div class="right">
                <el-card  v-for="(item, index) in questionList" :key="index">
                    <div class="d-flex">
                        <span>{{ index + 1 }}.</span>
                        <div>
                            <div>
                                问题描述：{{ item.confirmProblem }}
                            </div>
                            <div>
                                所属规范：{{ item.drStandardName }}
                            </div>
                            <div>
                                所属条文：{{ item.drStandardItemName }}
                            </div>
                            <div>
                                图纸位置：{{ formatPath(item.drProjectFilePath) }}{{item.opinionType===124?item.drawingName:''}}{{item.opinionType===126?item.locationDescription:''}}
                                <el-link v-if="item.positionImg && item.positionImg.length > 0" type="primary" @click="checkScreenshots(item.positionImg)">查看截图</el-link>
                            </div>
                            <div>
                                审查内容：{{ item.drAuditContentName }}
                            </div>
                        </div>
                    </div>
                    <el-button size="mini" @click="delectQuestion(item)">删除</el-button>
                </el-card>
            </div>
        </div>
        <!-- bottom end -->
        <el-dialog
        title="确认删除"
        :visible.sync="handleDeleteVisible"
        v-model="handleDeleteVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item>
                    确认删除该意见吗？
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleDeleteVisible = false">取消</el-button>
                <el-button @click.native="confirmDelete" :loading="deleteVisibleLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog
        title="提交合并意见"
        :visible.sync="handleCommitVisible"
        v-model="handleCommitVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item>
                    是否提交图审意见？提交图审意见后，所有问题即刻流转至总工室汇总！
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleCommitVisible = false">取消</el-button>
                <el-button @click.native="confirmCommit" :loading="commitVisibleLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>
        <el-dialog
        title="图纸截图"
        :visible.sync="showScreenshotVisible"
        v-model="showScreenshotVisible"
        :close-on-click-modal="false"
        >
            <div>
                <img :src="positionImg" width="100%" height="100%" />
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="showScreenshotVisible = false">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import { getUncommitProjectList,getOpinionAllList,deleteOpinion,submitDrawingReviewReport,getProjectFileInfo } from "@/api/api";
export default {
    components: { Toolbar },
    data() {
        return {
            utils: '',
            currentProject: undefined,
            selectFile: undefined,
            currentProjectName: '',
            currentFile: undefined,
            currentOpinion: undefined,
            handleDeleteVisible: false,
            deleteVisibleLoading: false,
            handleCommitVisible: false,
            commitVisibleLoading: false,
            loading: false,
            currentProjectList: [],
            auditList: [],
            filterText: '',//树形筛选关键词
            drawingDirectory: [],
            // 设置设计图树形结构属性
            defaultProps: {
                children: 'childFiles',
                label: 'fileName'
            },
            questionList: [],
            showScreenshotVisible: false,
            positionImg:'',
        };
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        },
        'currentProject': function(){
            if(this.currentProject){
                this.getDrawingDirectory();
                this.getQuestionList();
            }
        }
    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getCurrentProjectList();
    },
    methods: {
        // 获取当前在审项目
        getCurrentProjectList(){
            getUncommitProjectList({}).then(res => {
                var result = res.data
                if(result.success) {
                    this.currentProjectList = result.response;
                    if(result.response && result.response.length > 0){
                        this.currentProject = result.response[0];
                        this.currentProjectName = result.response[0].projectName;
                    }else{
                        this.currentProject = {};
                        this.currentProjectName = "";
                        this.drawingDirectory = [];
                    }
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        changeProject(val){
            this.currentProject = this.currentProjectList[val];
        },
        // 获取设计图结构目录
        getDrawingDirectory() {
            var params = {
                projectId : this.currentProject.projectId,
            }
            getProjectFileInfo(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.drawingDirectory = result.response.files;
                }
            })
        },
        // 获取问题清单
        getQuestionList() {
            var params = {
                projectId: this.currentProject.projectId,
            }
            if(this.currentFile){
                params.projectFileId = this.currentFile.id;
            }
            getOpinionAllList(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.questionList = result.response;
                }
            })
        },
        // 查看截图
        checkScreenshots(positionImg) {
            this.showScreenshotVisible = true;
            this.positionImg = positionImg;
        },
        // 删除意见
        delectQuestion(item) {
            this.handleDeleteVisible = true;
            this.currentOpinion = item;
        },
        confirmDelete() {
            this.deleteVisibleLoading = true
            deleteOpinion({id: this.currentOpinion.id}).then((res) => {
                this.handleDeleteVisible = false;
                var result = res.data
                if(result.success){
                    this.getQuestionList();
                }else{
                    this.$message.error(result.message);
                }
                this.deleteVisibleLoading = false
            });
        },
        // 填写图审意见
        writeSuggest(){
            window.open('qddfdr:\\url?param=param', '_blank');
        },
        // 提交图审意见
        submitSuggest() {
            this.handleCommitVisible = true;
        },
        confirmCommit() {
            this.commitVisibleLoading = true;
            submitDrawingReviewReport({projectId: this.currentProject.projectId}).then((res) => {
                this.commitVisibleLoading = false;
                var result = res.data
                if(result.success){
                    this.handleCommitVisible = false;
                    this.$message.success("提交意见成功！");
                    this.getCurrentProjectList();
                }else{
                    this.$message.error(result.message);
                }
            })
        },
        //树形筛选
        filterNode(value, data) {
            if (!value) return true;
            return data.fileName.indexOf(value) !== -1;
        },
        handleNodeClick(data) {
            this.selectFile = data;
            this.getFileOpinion(data);
        },
        getFileOpinion(fileData){
            var params = {
                projectId: this.currentProject.projectId,
            }
            if(fileData.fileType === 1){
                params.projectFileId = fileData.id;
            }else if(fileData.fileType === 2){
                params.projectFolderId = fileData.folderId;
            }
            getOpinionAllList(params).then(res => {
                var result = res.data
                if(result.success) {
                    this.questionList = result.response;
                }
            })
        },
        formatPath(path) {
            return path.replace(/(.*?)\/(.*?)\/(.*?)\//, '');
        },
    },
};
</script>

<style lang="stylus" scoped>
.d-flex {
  display: flex;
}

.list-container {
  flex-direction: column;

  .top {
    height: 40px;
    padding: 0 10px;
    justify-content: space-between;
  }
  .bottom{
    height: 600px;
    >>>.el-divider {
        height: 100%;
    }
    .left{
        padding: 0 10px 20px 10px;
        width:30%;
        flex-direction: column;
        justify-content: space-between;
    }
    .right{
        flex: 1;
        padding: 0 20px;
        overflow-y: scroll;
        
        >>>.el-card{
            margin-bottom: 20px;
            .el-card__body{
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }
        }
    }
  }
}
</style>